.personalInfo {
    padding: 5%;
    text-align: left;
}

.profileImg {
    padding: 5%;
    width: 100%;
}

.listGroup {
    margin-top: 30px;
    text-align: left;
}

.researchInfo {
    margin-top: 20px;
    text-align: left;
}

.publicationList {
    padding-top: 10px;
}

.publicationItem {
    padding: 10px;
}

.researchInfo {
    padding: 2%;
}