.machineDownText {
    color: rgb(255,99,99);
    text-align: left;
}

.freeText {
    color: rgba(60, 184, 120, 1.0);
    font-size: 14px
}

.usedText {
    color: rgb(255,99,99);
    font-size: 14px;
}

.freeBox {
    border-radius: 18px;
    background-color: rgba(60, 184, 120, 0.25);
    padding: 5px 2px;
    padding-top: 5px;
    padding-bottom: 5px;
    padding-right: 2px;
    padding-left: 2px;
}

.usedBox {
    border-radius: 18px;
    background-color: rgba(255,99,99, 0.25);
    padding-top: 5px;
    padding-bottom: 5px;
    padding-right: 2px;
    padding-left: 2px;
}