.tableTitle {
    text-align: left;
    height: 80px;
}

tr:nth-child(odd) { 
    background-color: rgb(243, 246, 249);
} 

.mainTable {
    border-style: solid;
    border-width: 1px;
    border-color: rgb(220, 232, 226);
}