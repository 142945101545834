.headline {
    background-color: rgb(243, 246, 249);;
    height: 80px;
    position: relative;
}

.title {
    margin: 0;
    position: absolute;
    top: 50%;
    left: 50%;
    -ms-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
}

.updateTime {
    margin: 0;
    position: absolute;
    top: 25%;
    right: 50%;
    //-ms-transform: translate(-50%, -50%);
    //transform: translate(-50%, -50%);
}

.cardContainer {
    height: 100%;
    border-style: solid;
    border-width: 1px;
    border-color: rgb(220, 232, 226);
}