.loadingDataText {
    margin-top: 100px;
    color: gray;
}

.cardContainer {
    background-color: rgb(243, 246, 249);;
}

.loader {
    margin-top: 150px;
}